/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
import React, { Component, lazy } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Route, Switch } from 'react-router-dom';

import TagManager from 'react-gtm-module';

import APP_ROUTING from 'constants/appRouting';

import NoMatchPage from 'components/NoMatchPage';

import ErrorBoundary from 'helpers/ErrorBoundary';

import Router from 'wrapperComponents/BrowserRouter';

// eslint-disable-next-line import/no-named-as-default
import PrivateRoute from 'containers/PrivateRoute';

import { isAuthenticated } from 'actions/AuthActions';
import { changeLanguage } from 'actions/LanguageActions';

import { APP_USE_TREEZOR } from 'constants/treezor';

import { SUBSCRIPTION_TYPE } from 'constants/subscriptions';

const CompanySignUpConfirmation = lazy(() =>
  import('components/CompanySignUpConfirmation')
);
const Settings = lazy(() => import('containers/Settings'));
const App = lazy(() => import('containers/App'));
const Callback = lazy(() => import('containers/Callback'));
const CallbackBankin = lazy(() => import('containers/Bank/CallbackBankin'));
const Logout = lazy(() => import('containers/Logout'));
const Signup = lazy(() => import('containers/AppSignup'));
const Home = lazy(() => import('containers/Home'));
const ExpensesPage = lazy(() => import('containers/ExpensesPage/Main'));
const ExpenseForm = lazy(() => import('containers/ExpensesPage/NewForm'));
const ExpensePdf = lazy(() => import('containers/ExpensesPage/Pdf'));
const PurchaseViewContainer = lazy(() =>
  import('containers/purchase/view/PurchaseViewContainer')
);
const ExpensesList = lazy(() => import('containers/ExpensesPage/List'));
const AdvanceExpenseForm = lazy(() =>
  import('containers/AdvanceExpenses/Form')
);
const InternalActivitiesList = lazy(() =>
  import('containers/InternalActivities/List')
);
const ClientsList = lazy(() => import('containers/Clients/List'));
const ClientImport = lazy(() => import('containers/Clients/ImportFile'));
const ClientProjectsList = lazy(() => import('containers/ClientProjects/List'));
const UsersList = lazy(() => import('containers/UsersPage/List'));
const Account = lazy(() => import('containers/UserProfile'));
const InvoiceForm = lazy(() => import('containers/Invoice/Form'));
const InvoicePdf = lazy(() => import('containers/Invoice/Pdf'));
const ItemsList = lazy(() => import('containers/Item/List'));
const ImportItemList = lazy(() => import('containers/Item/ImportItemList'));
const RolesList = lazy(() => import('containers/Roles/List'));
const RoleForm = lazy(() => import('containers/Roles/Form'));
const ProviderList = lazy(() => import('containers/ProvidersPage/List'));
const ProviderImport = lazy(() =>
  import('containers/ProvidersPage/ImportFile')
);
const CompanyForm = lazy(() => import('containers/Company'));
const BookKeepingExports = lazy(() => import('containers/BookKeeping/Exports'));
const BookKeepingExportsTest = lazy(() =>
  import('containers/BookKeeping/Exports/ExportTest')
);
const RevenueForm = lazy(() => import('containers/revenue/Form'));
const TreezorContainer = lazy(() =>
  import('containers/CardContainers/Treezor')
);
const BankAccounts = lazy(() => import('containers/Bank/BankAccounts'));
const CreateOnlyNewTreezorUser = lazy(() =>
  import(
    'containers/CardContainers/Treezor/userFormContainer/CreateNewUserAfterOnboarding/createOnlyNewTreezorUser'
  )
);
const TemplateList = lazy(() => import('containers/Template/List'));
const TemplateForm = lazy(() => import('containers/Template/Form'));
const EmailTemplateList = lazy(() => import('containers/EmailTemplate/List'));
const CustomerDocumentPage = lazy(() => import('containers/Customer/Document'));
const Login = lazy(() => import('containers/AuthPage/Login'));
const SEPARequestList = lazy(() =>
  import('containers/BankBBF/pages/SEPARequestList')
);
const SEPAViewRequestForm = lazy(() =>
  import('containers/BankBBF/pages/SEPAViewRequestForm')
);
const SEPARequestForm = lazy(() =>
  import('containers/BankBBF/pages/SEPARequestForm')
);
const BankBBFAccount = lazy(() =>
  import('containers/BankBBF/pages/BankBBFAccounts')
);
const BankBBFDeletePayout = lazy(() =>
  import('containers/BankBBF/pages/BankBBFDeletePayout')
);
const TransferPage = lazy(() =>
  import('containers/BankBBF/pages/TransferPage')
);
const WalletDeletePage = lazy(() =>
  import('containers/BankBBF/pages/WalletDeletePage')
);
const PurchaseForm = lazy(() => import('containers/purchase/PurchaseForm'));
const PurchaseList = lazy(() =>
  import('containers/purchase/list/PurchaseList')
);
const DashBoardExpense = lazy(() => import('containers/DashBoardExpense'));
const PaypalPage = lazy(() => import('containers/Customer/Paypal/index'));
const CompanyOwners = lazy(() => import('containers/CompanyOwners'));
const AddOwnerContainer = lazy(() =>
  import('containers/CompanyOwners/components/AddOwnerContainer')
);
const CardContainer = lazy(() =>
  import('containers/CardContainers/CardContainer')
);
const SelectCards = lazy(() =>
  import('containers/CardContainers/CardContainer/SelectCards')
);
const HipayPage = lazy(() => import('containers/Customer/HiPay'));
const TreezorPending = lazy(() =>
  import(
    'components/CardComponents/Treezor/TreezorDocsPending/treezorDocsPending'
  )
);
const DocInvalid = lazy(() =>
  import('containers/CardContainers/Treezor/docNeed')
);
const DocNeeded = lazy(() =>
  import('containers/CardContainers/Treezor/OneMoreDocNeeded')
);
const CardList = lazy(() => import('components/CardComponents/CardList'));
const RevenueDetail = lazy(() =>
  import('components/RevenueForms/SimpleForm/RevenueDetail')
);
const CardDetail = lazy(() => import('containers/CardContainers/CardDetail'));
const BankAccountTransactions = lazy(() =>
  import('containers/Bank/BankAccountTransactions')
);
const PDFManagement = lazy(() => import('containers/PDFManagement'));
const PDFSplit = lazy(() => import('containers/PDFManagement/Split'));
const PDFHistory = lazy(() => import('containers/PDFHistory'));
const PDFItem = lazy(() => import('containers/PDFHistory/PDFItem'));
const PDFArchive = lazy(() => import('containers/PDFArchive'));
const Deposit = lazy(() => import('containers/Deposit'));
const ArchivedDeposit = lazy(() =>
  import('containers/Deposit/ArchivedDeposit')
);
const Projects = lazy(() => import('containers/Project'));
const CreditNoteBankInfo = lazy(() => import('containers/CreditNoteBankInfo'));
const Onboarding = lazy(() => import('containers/Onboarding'));
const StripePricingTablePage = lazy(() =>
  import('containers/StripePricingTable')
);
const Subscription = lazy(() => import('containers/Subscription/List'));
const Register = lazy(() => import('containers/AuthPage/Register'));
const Categories = lazy(() => import('containers/Categories/List'));
const AnalyticalFields = lazy(() => import('containers/AnalyticalFields'));
const AgentIA = lazy(() => import('containers/AgentIA'));
const BankTransfer = lazy(() => import('containers/Bank/BankTransfer'));
const Transaction = lazy(() => import('containers/Transaction'));
const SelectSubscription = lazy(() => import('containers/SelectSubscription'));
const InvoicesList = lazy(() => import('containers/Invoice/List'));

class MyRouter extends Component {
  constructor() {
    super();
    this.state = {
      screenName: '',
      activitiesTreezor: false,
    };

    this.setScreenName = this.setScreenName.bind(this);
  }

  componentDidMount() {
    const {
      loggedUser: { user },
      appInitialized,
      loggedUserCompany,
    } = this.props;
    const isActivateTreezor = Boolean(
      loggedUserCompany?.company?.isActivateTreezor
    );
    this.setState({
      activitiesTreezor: APP_USE_TREEZOR && isActivateTreezor,
    });

    if (user && user.language && !appInitialized) {
      this.props.changeLanguage(user.language);
    }
  }

  componentDidUpdate(preProps) {
    const {
      loggedUser: { user },
      appInitialized,
    } = this.props;
    if (user !== preProps.loggedUser.user) {
      if (user && user.language && !appInitialized) {
        this.props.changeLanguage(user.language);
      }
    }
  }

  setScreenName(screenName) {
    this.setState({ screenName });
  }

  render() {
    return (
      <ErrorBoundary>
        <Router>
          <Switch>
            <Route
              exact
              path="/"
              render={(props) => (
                <App setScreenName={this.setScreenName} {...props} />
              )}
            />
            <Route
              exact
              path="/register"
              render={(props) => (
                <Register setScreenName={this.setScreenName} {...props} />
              )}
            />
            <Route
              exact
              path="/login"
              render={(props) => <Login {...props} />}
            />

            <Route
              exact
              path={APP_ROUTING.ONBOARDING}
              render={(props) => <Onboarding {...props} />}
            />
            <Route
              exact
              path="/select-subscription/:companyId"
              render={(props) => <SelectSubscription {...props} />}
            />

            <Route
              exact
              path="/stripe-pricing"
              render={(props) => <StripePricingTablePage {...props} />}
            />

            <Route
              exact
              path="/customer/document/:id/:clientId"
              render={(props) => (
                <CustomerDocumentPage
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <Route
              exact
              path="/customer/paypal/:id/:clientId/:paypalClientId"
              render={(props) => (
                <PaypalPage {...props} setScreenName={this.setScreenName} />
              )}
            />
            <Route
              exact
              path="/customer/hipay/:status"
              render={(props) => (
                <HipayPage {...props} setScreenName={this.setScreenName} />
              )}
            />
            <Route
              exact
              path="/signup"
              render={(props) => (
                <Signup {...props} setScreenName={this.setScreenName} />
              )}
            />
            <Route
              exact
              path="/signup-confirm"
              render={(props) => (
                <CompanySignUpConfirmation
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <Route
              exact
              path="/callback"
              render={(props) => (
                <Callback {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/treezor-onboarding"
              render={(props) => <TreezorContainer {...props} />}
            />
            <PrivateRoute
              exact
              path="/treezorPending"
              render={(props) => <TreezorPending {...props} />}
            />
            <PrivateRoute
              exact
              path="/treezorDocumentsInvalids"
              render={(props) => <DocInvalid {...props} />}
            />
            <PrivateRoute
              exact
              path="/treezorDocumentNeeded"
              render={(props) => <DocNeeded {...props} />}
            />
            <PrivateRoute
              exact
              path="/cards"
              render={(props) => <CardList {...props} />}
            />
            <PrivateRoute
              exact
              path="/NewTreezorUser/:id"
              render={(props) => <CreateOnlyNewTreezorUser {...props} />}
            />
            <PrivateRoute
              exact
              path="/cards/select/:id"
              render={(props) => <SelectCards {...props} />}
            />
            <PrivateRoute
              path="/card/virtual/new"
              render={(props) => <CardContainer virtual={true} {...props} />}
            />
            <PrivateRoute
              path="/card/physical/new"
              render={(props) => <CardContainer virtual={false} {...props} />}
            />
            <PrivateRoute
              exact
              path="/card/:id"
              render={(props) => <CardDetail {...props} />}
            />
            <PrivateRoute
              exact
              path="/bank-bbf/accounts"
              subscriptionTypes={[
                SUBSCRIPTION_TYPE.PAID_TIER,
                SUBSCRIPTION_TYPE.CUSTOM_TIER,
              ]}
              restrictedFeature={!this.state.activitiesTreezor}
              render={(props) => (
                <BankBBFAccount {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/bank-bbf/wallet/delete"
              subscriptionTypes={[
                SUBSCRIPTION_TYPE.PAID_TIER,
                SUBSCRIPTION_TYPE.CUSTOM_TIER,
              ]}
              render={(props) => (
                <WalletDeletePage
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <PrivateRoute
              exact
              path="/bank-bbf/delete/payout"
              subscriptionTypes={[
                SUBSCRIPTION_TYPE.PAID_TIER,
                SUBSCRIPTION_TYPE.CUSTOM_TIER,
              ]}
              render={(props) => (
                <BankBBFDeletePayout
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <PrivateRoute
              exact
              subscriptionTypes={[
                SUBSCRIPTION_TYPE.PAID_TIER,
                SUBSCRIPTION_TYPE.CUSTOM_TIER,
              ]}
              path="/bank-bbf/sepa/request"
              render={(props) => (
                <SEPARequestForm
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <PrivateRoute
              exact
              subscriptionTypes={[
                SUBSCRIPTION_TYPE.PAID_TIER,
                SUBSCRIPTION_TYPE.CUSTOM_TIER,
              ]}
              path="/bank-bbf/sepa/request/view"
              render={(props) => (
                <SEPAViewRequestForm
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <PrivateRoute
              exact
              subscriptionTypes={[
                SUBSCRIPTION_TYPE.PAID_TIER,
                SUBSCRIPTION_TYPE.CUSTOM_TIER,
              ]}
              path="/bank-bbf/sepa/requests"
              render={(props) => (
                <SEPARequestList
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <PrivateRoute
              exact
              subscriptionTypes={[
                SUBSCRIPTION_TYPE.PAID_TIER,
                SUBSCRIPTION_TYPE.CUSTOM_TIER,
              ]}
              path="/bank-bbf/transfer"
              render={(props) => (
                <TransferPage {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              subscriptionTypes={[
                SUBSCRIPTION_TYPE.PAID_TIER,
                SUBSCRIPTION_TYPE.CUSTOM_TIER,
              ]}
              exact
              path="/bank/account/:id"
              render={(props) => (
                <BankAccountTransactions
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <PrivateRoute
              exact
              subscriptionTypes={[
                SUBSCRIPTION_TYPE.PAID_TIER,
                SUBSCRIPTION_TYPE.CUSTOM_TIER,
              ]}
              path="/callback/bankin"
              render={(props) => {
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const responseFromBankinApi = {
                  success: urlParams.get('success'),
                  user_uuid: urlParams.get('user_uuid'),
                  step: urlParams.get('step'),
                  item: urlParams.get('item_id'),
                };
                return (
                  <CallbackBankin
                    {...props}
                    responseFromBankinApi={responseFromBankinApi}
                    setScreenName={this.setScreenName}
                  />
                );
              }}
            />
            <Route
              path="/logout"
              render={(props) => (
                <Logout {...props} setScreenName={this.setScreenName} />
              )}
            />
            <Route
              path="/companyOwners"
              render={(props) => <CompanyOwners {...props} />}
            />
            <Route
              path="/addOwner"
              render={(props) => <AddOwnerContainer {...props} />}
            />

            <PrivateRoute
              exact
              path="/bank/accounts"
              subscriptionTypes={[
                SUBSCRIPTION_TYPE.PAID_TIER,
                SUBSCRIPTION_TYPE.CUSTOM_TIER,
              ]}
              render={(props) => (
                <BankAccounts {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              subscriptionTypes={[
                SUBSCRIPTION_TYPE.PAID_TIER,
                SUBSCRIPTION_TYPE.CUSTOM_TIER,
              ]}
              path="/bank/transfer"
              render={(props) => (
                <BankTransfer {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              subscriptionTypes={[
                SUBSCRIPTION_TYPE.PAID_TIER,
                SUBSCRIPTION_TYPE.CUSTOM_TIER,
              ]}
              path="/bank/account/:id"
              render={(props) => (
                <BankAccountTransactions
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <PrivateRoute
              exact
              path="/home"
              shouldFillMandatoryData={false}
              render={(props) => (
                <Home {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/expenses"
              restrictedFeature="userHasExpenseAccess"
              render={(props) => (
                <ExpensesPage {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/expenses/new"
              restrictedFeature="userCanCreateExpense"
              render={(props) => (
                <ExpenseForm {...props} setScreenName={this.setScreenName} />
              )}
            />

            <PrivateRoute
              exact
              path="/purchases/new"
              restrictedFeature="userCanCreatePurchase"
              render={(props) => (
                <PurchaseForm {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/revenue/new"
              render={(props) => (
                <RevenueForm {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature="userCanUpdatePurchase"
              path="/purchases/edit/:id"
              render={(props) => (
                <PurchaseViewContainer
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <PrivateRoute
              exact
              path="/pdf"
              render={(props) => (
                <PDFManagement {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/pdf/split"
              render={(props) => (
                <PDFSplit {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/pdf/:id"
              render={(props) => (
                <PDFItem {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/pdf/item/:id"
              render={(props) => (
                <PDFHistory {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/pdf/item/:id/archive"
              render={(props) => (
                <PDFArchive {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/expenses/edit/:id"
              restrictedFeature={{
                $or: ['userCanUpdateExpense', 'userCanReadExpense'],
              }}
              render={(props) => (
                <ExpenseForm {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature="userCanCreateAdvanceExpense"
              path="/expenses/advance/new"
              render={(props) => (
                <AdvanceExpenseForm
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature={{
                $or: [
                  'userCanUpdateAdvanceExpense',
                  'userCanReadAdvanceExpense',
                ],
              }}
              path="/expenses/advance/edit/:id"
              render={(props) => (
                <AdvanceExpenseForm
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature={{
                $or: ['userHasAdvanceExpenseAccess', 'userCanReadExpense'],
              }}
              path="/expenses/list"
              render={(props) => (
                <ExpensesList
                  {...props}
                  setScreenName={(screenName) => {
                    this.setState({ screenName });
                  }}
                />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature={{
                $or: ['userHasAdvanceExpenseAccess', 'userHasExpenseAccess'],
              }}
              path="/dashboard/overviewExpenses"
              render={() => <DashBoardExpense />}
            />
            <PrivateRoute
              exact
              restrictedFeature={{
                $or: ['userHasPurchaseAccess'],
              }}
              path="/purchases/list"
              render={(props) => (
                <PurchaseList
                  {...props}
                  setScreenName={(screenName) => {
                    this.setState({ screenName });
                  }}
                />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature="userHasBookKeepingAccess"
              path="/bookKeeping/export"
              render={(props) => (
                <BookKeepingExports
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature="userHasBookKeepingAccess"
              path="/bookKeeping/export/test"
              render={(props) => (
                <BookKeepingExportsTest
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature="userHasAgentAccess"
              path="/agent/chat/:id"
              render={(props) => (
                <AgentIA {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature="userHasAgentAccess"
              path="/agent/chat"
              render={(props) => (
                <AgentIA {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/invoices/list"
              restrictedFeature="userHasInvoiceAccess"
              render={(props) => (
                <InvoicesList {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/invoices/new"
              restrictedFeature="userCanCreateInvoice"
              render={(props) => (
                <InvoiceForm {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/invoices/edit/:id"
              restrictedFeature={{
                $or: ['userCanReadInvoice', 'userCanUpdateInvoice'],
              }}
              render={(props) => (
                <InvoiceForm {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/invoices/pdf/:id/:type"
              restrictedFeature="userCanReadInvoice"
              render={(props) => (
                <InvoicePdf {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              shouldFillMandatoryData={false}
              path="/account"
              render={(props) => (
                <Account {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/settings"
              restrictedFeature="userHasCompanySettingsAccess"
              render={(props) => (
                <Settings {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/settings/internal-activities"
              render={(props) => (
                <InternalActivitiesList
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature="userHasClientAccess"
              path="/settings/clients"
              render={(props) => (
                <ClientsList {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature="userHasClientAccess"
              path={APP_ROUTING.IMPORT_COMPANIES}
              render={(props) => (
                <ClientImport
                  {...props}
                  setScreenName={this.setScreenName}
                  isCompany
                  screenType="IMPORT COMPANIES"
                />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature="userHasClientAccess"
              path={APP_ROUTING.IMPORT_INDIVIDUALS}
              render={(props) => (
                <ClientImport
                  {...props}
                  setScreenName={this.setScreenName}
                  isCompany={false}
                  screenType="IMPORT INDIVIDUALS"
                />
              )}
            />
            <PrivateRoute
              exact
              path="/settings/projects"
              render={(props) => (
                <ClientProjectsList
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <PrivateRoute
              exact
              path="/settings/users"
              restrictedFeature="userHasUserManagementAccess"
              render={(props) => (
                <UsersList {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/settings/providers"
              render={(props) => (
                <ProviderList {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path={APP_ROUTING.ITEM_LIST}
              restrictedFeature="userHasItemAccess"
              render={(props) => (
                <ItemsList {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path={APP_ROUTING.IMPORT_ITEM_LIST}
              restrictedFeature="userHasItemAccess"
              render={(props) => (
                <ImportItemList {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature="userHasUserRoleManagementAccess"
              path="/settings/roles"
              render={(props) => (
                <RolesList {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature="userCanCreateUserRoleManagement"
              path="/settings/roles/new"
              render={(props) => (
                <RoleForm {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature="userCanUpdateUserRoleManagement"
              path="/settings/roles/edit/:id"
              render={(props) => (
                <RoleForm {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              shouldFillMandatoryData={false}
              path="/settings/company"
              render={(props) => (
                <CompanyForm {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature="userHasTemplateAccess"
              path="/settings/template"
              render={(props) => (
                <TemplateList {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature="userCanCreateTemplate"
              path="/settings/template/new"
              render={(props) => (
                <TemplateForm {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature="userCanUpdateTemplate"
              path="/settings/template/edit/:id"
              render={(props) => (
                <TemplateForm {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              // restrictedFeature="userCanUpdateEmailTemplate"
              path="/settings/emailTemplates"
              render={(props) => (
                <EmailTemplateList
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <PrivateRoute
              exact
              // restrictedFeature="userCanUpdateEmailTemplate"
              path="/settings/subscriptions"
              render={(props) => (
                <Subscription {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              shouldFillMandatoryData={false}
              path="/settings/categories"
              render={(props) => (
                <Categories {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              shouldFillMandatoryData={false}
              path="/settings/analytical-fields"
              render={(props) => (
                <AnalyticalFields
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <PrivateRoute
              exact
              // restrictedFeature="userCanUpdateEmailTemplate"
              path={APP_ROUTING.IMPORT_PROVIDER}
              render={(props) => (
                <ProviderImport {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/transaction/:type/:id"
              render={(props) => (
                <Transaction {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/revenue/:id"
              render={(props) => (
                <RevenueDetail {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/deposit"
              render={(props) => (
                <Deposit {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/deposit/archive"
              render={(props) => (
                <ArchivedDeposit
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <PrivateRoute
              exact
              path="/projects"
              render={(props) => (
                <Projects {...props} setScreenName={this.setScreenName} />
              )}
              restrictedFeature="userHasProjectAccess"
            />
            <Route
              path="/refund-bank-info"
              render={(props) => (
                <CreditNoteBankInfo
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <Route
              render={(props) => (
                <NoMatchPage {...props} setScreenName={this.setScreenName} />
              )}
            />
          </Switch>
        </Router>
      </ErrorBoundary>
    );
  }
}

MyRouter.propTypes = {
  loggedUser: PropTypes.object,
  loggedUserCompany: PropTypes.object,
  isAuthenticated: PropTypes.func.isRequired,
  changeLanguage: PropTypes.func,
  appInitialized: PropTypes.bool,
};

function mapStateToProps({ loggedUser, appInitialized, loggedUserCompany }) {
  return { loggedUser, appInitialized, loggedUserCompany };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ isAuthenticated, changeLanguage }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MyRouter);
